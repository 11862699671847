.sidebar {
  position: sticky;
  top: 0;
  left: 0;
  width: 20%;
  max-width: 20%;
  height: 100vh;
  overflow-y: auto;
  color: #fff;
  transition: all 0.5s linear;
  font-family: "Poppins", "Nunito Sans", sans-serif;
  display: flex;
  flex-direction: column !important;
  align-items: flex-start;
  justify-content: space-between;
  background-color: black;
  border-right: 1px solid #212020;
  /* overflow-y: auto; */
}

.collapseSidebar {
  /* background-color: yellow; */
  width: 10% !important;
  max-width: 10% !important;
}

.elementWrapper {
  /* background-color: aliceblue; */
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 1rem;
  gap: 1rem;
}

.heading {
  display: flex;
}

.headingLogo {
  /* background-color: black; */
  width: 100%;
  max-width: 14rem;
}

.sidebarelement {
  padding: 0.4rem 0;
  padding-left: 1rem;
  font-size: 18px;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 1rem;
  flex-wrap: wrap;
}

.sidebarelement:hover {
  background-color: #110d03;
}

.sidebarelementactive {
  padding: 0.4rem 0;
  padding-left: 1rem;
  font-size: 18px;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 1rem;
  flex-wrap: wrap;
  border-right: 5px solid #e4c748;
  color: #c19e29;
}

.sidebarelementactive:hover {
  background-color: #110d03;
}

.elementText {
  text-wrap: wrap;
}

.hideElementText {
  display: none;
}

.logoutWrapper {
  /* background-color: lime; */
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 2.3rem;
  padding-bottom: 1.5rem;
  gap: 1rem;
  cursor: pointer;
}

.logout {
  width: 2rem;
  height: 2rem;
}

@media (max-width: 992px) {
  .sidebar {
    min-width: min-content;
    gap: 4rem;
  }
  .headingLogo {
    width: 55px;
    height: auto;
  }

  .elementWrapper {
    background-color: black;
    align-items: center;
    gap: 3rem;
  }

  .sidebarelement {
    padding-left: 0;
  }

  .sidebarelementactive {
    border-right: none;
    padding-left: 0;
  }

  .elementText {
    display: none;
  }

  .logoutWrapper {
    padding-left: 1.8rem;
  }
}
