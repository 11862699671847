.proofContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.9375rem; /* 15px */
}

.proofParagraph {
  width: 80%;
  margin-top: 0ch !important;
  padding-top: 0ch !important;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.5;
  text-align: center;
  color: white;
}

.proofItems {
  /* background-color: aliceblue; */
  width: 100%;
  max-width: 80vw;
  margin: 0 auto;
}

.proofImg {
  width: auto;
  max-width: 90%;
  height: auto;
  min-height: 25rem;
  max-height: 45rem;
  background-color: #4e4e4e;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  object-fit: cover;
}

/* Common Arrow Styles */
.customArrow {
  width: 3rem;
  height: 3rem;
  background: linear-gradient(
    91.94deg,
    #b17e32 -8.77%,
    #f3e681 58.63%,
    #cf9f41 107.45%
  ) !important;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.customArrow:hover {
  background: linear-gradient(
    91.94deg,
    #f3e681 -8.77%,
    #b17e32 58.63%,
    #cf9f41 107.45%
  ) !important;
}

/* Previous Arrow */
.prev {
  left: -50px;
}

/* Next Arrow */
.next {
  right: -50px;
}

/* Icon inside arrows */
.customArrow::after {
  content: "❮";
  font-size: 1rem;
  color: black;
}

.next::after {
  content: "❯";
}

@media (max-width: 768px) {
  .customArrow {
    display: none !important;
  }
}
