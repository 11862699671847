.footerSection {
  padding: 5rem 9%;
  background-color: #0f0f0f;
  font-family: "Popins", "Nunito Sans", sans-serif;
}

.footer {
  color: #b2bbcc;
  font-family: "Poppins";
}

.footerBoxContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.footerBox {
  flex: 1;
}

.footerBox h3 {
  color: #ffffff;
  font-weight: 600;
  font-size: 0.90rem;
  line-height: 1rem;
  padding: 1rem 0;
  font-family: "Poppins" !important;
}

.footerBanner {
  background: linear-gradient(
    91.94deg,
    #b17e32 -8.77%,
    #f3e681 58.63%,
    #cf9f41 107.45%
  );
  height: 0.25rem;
  width: 1.875rem;
  border-radius: 0.125rem;
  margin-top: 0ch !important;
  padding-top: 0ch !important;
  margin-bottom: 1rem;
}

.footerBox p {
  font-size: 0.75rem;
}

.footerBox a {
  font-family: "Poppins" !important;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
  display: block;
  color: #b2bbcc;
  padding: 0.5rem 0;
  text-decoration: none;
}

.footerBox a:hover {
  color: #fff;
}

.footerBox img {
  margin-top: 1rem;
}

.footerMedia {
  display: flex;
  gap: 0.625rem;
  font-size: 0.75rem;
}

.footerCredit {
  text-align: center;
  margin-top: 1.5rem;
  padding-top: 2.5rem;
  font-size: 0.75rem;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 0.5rem;
  color: #8c9ab4;
  border-top: 0.1rem solid rgba(255, 255, 255, 0.1);
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
