.container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  padding: 1.5rem;
  color: white;
}

.giftCard {
  background-color: #414141;
  padding: 1.5rem 1.75rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.giftImage {
  width: 100%;
  height: 150px;
  border-radius: 5px;
  object-fit: cover;
}
