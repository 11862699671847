.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.9375rem; /* 15px */
}

.paragraph {
  width: 80%;
  margin-top: 0ch !important;
  padding-top: 0ch !important;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.5;
  text-align: center;
  color: white;
}

.items {
  display: flex;
  flex-shrink: 1;
  gap: 1.25rem; /* 20px */
  justify-content: center;
}

.itemWrapper {
  position: relative;
  width: 30vw;
  height: 24rem;
  border-radius: 0.75rem; /* 4px */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}

.itemWrapper:hover {
  box-shadow: 0 0 1.25rem rgba(255, 255, 255, 0.3);
  transition: box-shadow 0.3s ease-in-out;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.playIconOverlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.playIcon {
  width: 3.125rem; /* 50px */
  height: 3.125rem; /* 50px */
  cursor: pointer;
}

/* Responsive Design */
@media (max-width: 768px) {
  .items {
    flex-direction: column;
  }

  .itemWrapper {
    height: auto;
    min-height: 28rem;
    max-height: 28rem;
    width: 88vw !important;
  }
}
