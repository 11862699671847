.packageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.packageParagraph {
  width: 80%;
  margin-top: 0ch !important;
  padding-top: 0ch !important;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.5;
  text-align: center;
  color: white;
}

.packageItems {
  width: 100%;
  max-width: 80vw;
  margin: 0 auto;
  /* background-color: aliceblue; */
}

.packageItemWrapper {
  display: flex !important;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 1.5rem !important;
  max-width: 30rem;
  width: 100%;
  background-color: #414141;
  /* background-color: aquamarine; */
  padding: 1.25rem;
  border-radius: 1rem;
  box-sizing: border-box;
}

.packageImg {
  width: 100%;
  height: auto;
  max-height: 32rem;
  background-color: #414141;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem; /* 14.63px */
  padding: 0;
  overflow: hidden;
}

.packageTitle {
  font-family: "Poppins", "Nunito Sans", sans-serif;
  font-weight: 500;
  font-size: 1.75rem;
  line-height: 2.25rem;
  background: linear-gradient(
    91.94deg,
    #b17e32 -8.77%,
    #f3e681 58.63%,
    #cf9f41 107.45%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.packageDetails {
  font-family: "Poppins", "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.25rem;
  color: white;
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: start;
  gap: 1rem !important;
  list-style: none;
  width: 100%;
}

.packageDetails li {
  font-family: "Poppins", "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 1.2rem; /* 16px */
  line-height: 2.125rem; /* 34px */
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem; /* 4px */
}

.packageDots {
  display: block;
  height: 0.5rem; /* 8px */
  width: 0.5rem; /* 8px */
  border-radius: 50%;
  background: linear-gradient(
    91.94deg,
    #b17e32 -8.77%,
    #f3e681 58.63%,
    #cf9f41 107.45%
  );
}

.packagePoints {
  flex-direction: column;
  list-style: disc;
}

.packagePoints li {
  font-family: "Poppins", "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 2;
  color: white;
}

.hrLine {
  border: 0.07625rem solid rgba(231, 233, 235, 0.3);
  width: 100%;
  height: 0;
}

.packagePriceWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.packagePrice {
  font-family: "Poppins", "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 1.75rem;
  line-height: 2.5rem;
  color: white;
  margin-right: 0.5rem;
}

.packagePriceCross {
  font-family: "Poppins", "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2.5rem;
  color: #9b9898;
  margin-right: 0.4375rem;
  text-decoration: line-through;
}

.packageBuy {
  background: linear-gradient(
    91.94deg,
    #b17e32 -8.77%,
    #f3e681 58.63%,
    #cf9f41 107.45%
  );
  border-radius: 50%;
  height: 2.5625rem; /* 41px */
  width: 2.5625rem; /* 41px */
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}

.packageBuy:hover {
  cursor: pointer;
  transform: scale(1.1);
  background: linear-gradient(
    91.94deg,
    #f3e681 -8.77%,
    #b17e32 58.63%,
    #cf9f41 107.45%
  ) !important;
}

.packageBuyIcon {
  font-size: 1.5rem; /* 22px */
}

/* Responsive Design for small screens */
@media (max-width: 768px) {
  .packageItemWrapper {
    width: 96% !important;
    height: 100% !important;
    min-height: 56rem;
    justify-content: space-between;
  }

  .packageImg {
    width: 100%; /* Adjust image width to 100% of the wrapper */
    height: auto; /* Maintain aspect ratio by auto-adjusting height */
  }

  .packagePoints li {
    font-family: "Poppins", "Nunito Sans", sans-serif;
    font-weight: 400;
    font-size: 1.5rem; /* 16px */
    line-height: 2; /* 34px */
    color: white;
  }
}

/* Common Arrow Styles */
.customArrow {
  width: 3rem;
  height: 3rem;
  background: linear-gradient(
    91.94deg,
    #b17e32 -8.77%,
    #f3e681 58.63%,
    #cf9f41 107.45%
  ) !important;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.customArrow:hover {
  background: linear-gradient(
    91.94deg,
    #f3e681 -8.77%,
    #b17e32 58.63%,
    #cf9f41 107.45%
  ) !important;
}

/* Previous Arrow */
.prev {
  left: -50px;
}

/* Next Arrow */
.next {
  right: -50px;
}

/* Icon inside arrows */
.customArrow::after {
  content: "❮";
  font-size: 1rem;
  color: black;
}

.next::after {
  content: "❯";
}

@media (max-width: 768px) {
  .customArrow {
    display: none !important;
  }
}
