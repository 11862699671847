/* Button Styling */
.btn {
  background: linear-gradient(
    91.94deg,
    #b17e32 -8.77%,
    #f3e681 58.63%,
    #cf9f41 107.45%
  ) !important;
  width: auto;
  min-width: max-content;
  padding: 1rem 2rem;
  color: black !important;
  font-weight: 700 !important;
  border: none !important;
  border-radius: 0.5rem;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.5s ease !important;
  text-align: center !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.btn:hover {
  background: linear-gradient(
    91.94deg,
    #f3e681 -8.77%,
    #b17e32 58.63%,
    #cf9f41 107.45%
  ) !important;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.btnDisabled {
  background: grey !important;
  background-color: grey !important;
}

.btnDisabled:hover {
  background: grey !important;
  background-color: grey !important;
}
