.clubParagraph {
  margin-top: 0ch !important;
  padding-top: 0ch !important;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.5;
  text-align: center;
  color: white;
}

.clubItemContainer {
  margin-top: 1.875rem;
}

.clubItem {
  width: 90% !important;
  height: 100% !important;
  /* min-height: 22rem; */
  /* background-color: lime; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.clubImg,
.clubVideo {
  border-radius: 0.25rem;
  width: 100%;
  height: 100%;
  min-height: 38rem;
  border: none;
  object-fit: cover; /* Ensures consistent aspect ratio */
}

/* Media query for small devices */
@media (max-width: 768px) {
  .clubImg,
  .clubVideo {
    min-height: 24rem !important;
    object-fit: fill;
  }
}

/* Common Arrow Styles */
.customArrow {
  width: 3rem;
  height: 3rem;
  background: linear-gradient(
    91.94deg,
    #b17e32 -8.77%,
    #f3e681 58.63%,
    #cf9f41 107.45%
  ) !important;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.customArrow:hover {
  background: linear-gradient(
    91.94deg,
    #f3e681 -8.77%,
    #b17e32 58.63%,
    #cf9f41 107.45%
  ) !important;
}

/* Previous Arrow */
.prev {
  left: -50px;
}

/* Next Arrow */
.next {
  right: -50px;
}

/* Icon inside arrows */
.customArrow::after {
  content: "❮";
  font-size: 1rem;
  color: black;
}

.next::after {
  content: "❯";
}


@media (max-width: 768px) {
  .customArrow {
    display: none !important;
  }
}